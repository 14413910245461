import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./api/apiSlice";
import authReducer from "../features/auth/authSlice";
import userReducer from "../features/user/userSlice";
import aboutUsReducer from "../features/aboutUs/aboutUsSlice";
import chatDataSlice from "../features/chatData/chatDataSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    user: userReducer,
    aboutUs: aboutUsReducer,
    chatter: chatDataSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  },
  devTools: false,
  // devTools: process.env.REACT_APP_ENV !== "dev" ? false : true,
});

export default store;
