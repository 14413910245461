import React, { createContext, useContext } from "react";
import { useWebSocket } from "../hooks/useWebSocket";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ url, children }) => {
  const { sendMessage, uid, receivedMessage } = useWebSocket(url);
  return (
    <WebSocketContext.Provider value={{ sendMessage, uid, receivedMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
