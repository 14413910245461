import React from "react";
import { useNavigate } from "react-router-dom";

export const Careers = React.forwardRef((_, ref) => {
  const navigate = useNavigate();

  const careerPageRedirect = () => {
    navigate("/career");
  };

  return (
    <div
      ref={ref}
      className="w-full border-b border-white"
      style={{
        background: "linear-gradient(80deg, #666666 -60%, #000000 80%)",
      }}
    >
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="flex flex-col md:flex-row items-center gap-10 py-[47px] md:py-[80px]">
          <div className="w-full flex flex-col items-center md:flex-col md:items-start md:w-[50%]">
            <h2 className="font-sora text-left text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] mb-[20px] md:mb-[30px] lg:mb-[45px]">
              Careers
            </h2>

            <div className="flex flex-col gap-1">
              <p className="font-sora text-center md:text-left text-[12px] md:text-[16px] font-normal leading-[20.16px]">
                Ready to take the next step? Join us today and become part of a
                dynamic, forward-thinking community.
              </p>
            </div>
          </div>
          <div className=" w-full md:w-[60%] flex justify-center md:justify-end">
            <button
              onClick={careerPageRedirect}
              className="bg-[#F7D504] w-full md:w-auto text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[17px] text-[#1A1A1A] hover-shadow-yellow transition-shadow duration-300"
            >
              See all Job Posting
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
