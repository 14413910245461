import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      role="presentation"
    >
      {/* Trigger Element */}
      <div className="group relative cursor-pointer">{children}</div>

      {/* Tooltip */}
      {isVisible && (
        <div className="bg-zinc-800 p-1.5 rounded-md flex absolute -top-2 -translate-y-full left-1/2 -translate-x-[55%]">
          <span className="font-sora text-sm text-gray-400 whitespace-nowrap">
            {text}
          </span>
          {/* Tooltip Arrow */}
          <div className="bg-inherit rotate-45 p-1 absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
