/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { AiOutlineAudio } from "react-icons/ai";
import { FiArrowUp } from "react-icons/fi";

import SenaBotRoboIcon from "../../assets/icon/senaAiIcon.png";
import SenaGroupIcon from "../../assets/icon/senaYellowFlag.svg";
import Loader from "../../assets/image/loader.gif";
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea";

export const AISearchBot = ({
  query,
  setQuery,
  onSubmit,
  botDataIsLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  const [animatePage, setAnimatePage] = useState("");
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, query);

  const senaBotRedirect = () => {
    setAnimatePage("move-page-left");
    setTimeout(() => {
      navigate("/chatbot");
    }, 500);
  };

  const homeRedirect = () => {
    setAnimatePage("move-page-right");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event?.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (query.trim()) {
      onSubmit(query);
    }
    setQuery("");
  };

  return (
    <div
      className={`z-[500] w-[85%] lg:w-[35%] flex items-center justify-between gap-2 max-w-4xl mx-auto px-[7px] py-[5px] bg-[rgba(255,255,255,0.17)] border border-white rounded-[20px] shadow-[0px_8px_50.6px_0px_#000000F2] ${animatePage}`}
    >
      <div
        className={`flex w-full rounded-[10px] transition-colors duration-300 ${
          isFocused ? "bg-[#2B2B2B]" : "bg-transparent"
        }`}
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <textarea
            type="text"
            value={query}
            ref={textAreaRef}
            rows={1}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask me anything"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            maxLength="1000"
            className=" w-[50px] flex-grow px-[9px] py-[6px] text-white bg-transparent border-none outline-none placeholder-[#2B2B2B] resize-none placeholder:text-[#969696]"
          />
        </form>
        <div className="flex items-center gap-1 space-x-2 px-[8px] py-[3px]">
          {/* <button className="flex items-center justify-center">
            <AiOutlineAudio className="text-white" />
          </button> */}
          <button
            onClick={() => {
              setIsFocused(false);
              handleSubmit();
            }}
            className="flex items-center justify-center bg-[rgba(255,255,255,0.1)] p-1 rounded-[8px] border border-[#969696]  hover:text-white"
          >
            {botDataIsLoading ? (
              <img src={Loader} alt="Loader" className="w-[24px]" />
            ) : (
              <FiArrowUp className="text-white" />
            )}
          </button>
        </div>
      </div>
      <div className="hidden md:contents w-full">
        <div className="px-3 py-[6px] cursor-pointer bg-black text-white font-bold rounded-[12px]">
          {location.pathname === "/chatbot" ? (
            <>
              {/* SenaBot page redirect button */}
              <div
                className="flex gap-3 w-full"
                onClick={homeRedirect}
                role="presentation"
              >
                <div className="flex items-center p-[2px]">
                  <img
                    src={SenaGroupIcon}
                    alt="aiYellowIcon"
                    className="w-[16px] h-auto"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Home page redirect button */}
              <div
                className="flex gap-3 w-full"
                onClick={senaBotRedirect}
                role="presentation"
              >
                <div className="flex items-center P-[2px]">
                  <img
                    src={SenaBotRoboIcon}
                    alt="senaBotRoboIcon"
                    className="h-[17.67px] object-contain invert"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AISearchBot;
