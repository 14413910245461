import { useEffect, useRef, useState } from "react";
import { createWebSocket } from "../services/webSocketService";

export const useWebSocket = (url) => {
  const ws = useRef(null);
  const [uid, setUid] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState("");

  useEffect(() => {
    if (!url) {
      console.error("WebSocket URL is missing");
      return;
    }

    ws.current = createWebSocket(url, (message) => {
      setReceivedMessage(message);
    });
    ws.current.connect();
    setUid(ws.current.uid);

    return () => {
      ws.current.disconnect();
    };
  }, [url]);

  const sendMessage = (message) => {
    ws.current.sendMessage(message);
  };

  return { sendMessage, uid, receivedMessage };
};
