import * as React from "react";
const MemorySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    className="mb-[-1px]"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6 3a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2V3ZM7 3v12h1.18l.31-1.548a1.5 1.5 0 0 1 .41-.766l3.393-3.393A2.406 2.406 0 0 1 14 8.586V3.5a.5.5 0 0 0-.5-.5H7Z"
    />
    <path
      fill="currentColor"
      d="m11.351 15.53-1.616.323a.5.5 0 0 1-.588-.588l.323-1.617a.5.5 0 0 1 .137-.255L13 10a1.414 1.414 0 1 1 2 2l-3.393 3.393a.5.5 0 0 1-.256.137Z"
    />
  </svg>
);
export default MemorySvg;
