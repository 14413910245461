import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/home/Home";
import { Career } from "./pages/Career";
import Blogs from "./pages/Blogs";
import { ChatBot } from "./pages/ChatBot";
import BlogDetail from "./pages/BlogDetail";
import LoginPage from "./pages/loginPage";

import SignUpPage from "./pages/signUpPage";
import "./App.css";
import ToastNotification from "./components/atom/notification";
import ForgetPasswordPage from "./pages/forgetPassword";
import ResetPasswordPage from "./pages/resetPassword";

function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chatbot" element={<ChatBot />} />
          <Route path="/blogs" element={<Blogs />} />
          {/* Modify the BlogDetail route to include the id as a parameter */}
          <Route path="/blogDetail/:id" element={<BlogDetail />} />
          <Route path="/career" element={<Career />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forget" element={<ForgetPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Routes>
      </Router>
      <ToastNotification />
    </div>
  );
}

export default App;
