import React from "react";
import { Link } from "react-router-dom";
import GoogleLoginForm from "../components/molecule/forms/GoogleLoginForm";
import Logo from "../assets/icon/senaLogo.svg";

const LoginPage = () => {
  return (
    <div>
      <div className="flex items-center justify-center p-4">
        <Link to="/">
          <img
            src={Logo}
            alt="logo"
            className="w-[100px] md:w-[110px] h-auto cursor-pointer"
          />
        </Link>
      </div>
      <GoogleLoginForm />
    </div>
  );
};

export default LoginPage;
