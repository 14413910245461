import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import GoogleLogo from "../../../assets/image/googleLogo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSignUpMutation } from "../../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import { signUpSchema } from "../../../validator/auth";

const SignUpForm = () => {
  const navigate = useNavigate();
  const initialState = {
    email: "",
    password: "",
    is_active: true,
    is_superuser: false,
    is_verified: false,
  };
  const [user, setUser] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [signUp] = useSignUpMutation();

  const signUpFormik = useFormik({
    initialValues: initialState,
    validationSchema: signUpSchema,
    onSubmit: async (e, { resetForm }) => {
      try {
        await signUp(e).unwrap();
        resetForm();
        toast.success("Sign up successful");
        navigate("/login");
      } catch (error) {
        console.error("error>>>", error);
        toast.error(error?.data?.detail ?? "Failed to signup");
      }
    },
  });

  const saveToLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const handleGoogleLogin = () => {
    const user = localStorage.getItem("user");
    if (!user) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/google/login`;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userString = urlParams.get("user");

    if (userString) {
      const userObject = {};
      const regex = /(\w+)=['"]([^'"]+)['"]/g; // Regular expression to match key="value" or key='value'
      let match;
      while ((match = regex.exec(userString)) !== null) {
        userObject[match[1]] = match[2]; // match[1] is key, match[2] is value
      }

      const user = {
        userId: userObject?.id,
        email: userObject?.email,
        firstName: userObject?.first_name,
        lastName: userObject?.last_name,
        displayName: userObject?.display_name,
        profilePicture: userObject?.picture,
        provider: userObject?.provider,
      };
      saveToLocal("user", user);
      setUser(user);

      console.log("Parsed User Object in Google form", userObject);
    }
  }, []);

  return (
    <div className=" flex flex-col items-center justify-center mb-52 mt-2 p-10 ">
      <form
        noValidate
        onSubmit={signUpFormik.handleSubmit}
        className="flex flex-col items-center max-w-[300px]"
      >
        <h1 className="font-sora text-center mb-4 text-[22px] font-bold">
          Sign up to use Sena
        </h1>

        <div className="mb-4">
          {/* Email Input */}
          <input
            type="email"
            name="email"
            value={signUpFormik.values.email}
            onChange={signUpFormik.handleChange}
            placeholder="name@example.com"
            className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
              signUpFormik.touched.email && signUpFormik.errors.email
                ? "border-red-500"
                : ""
            }`}
          />
          {/* Error Message */}
          {signUpFormik.touched.email && signUpFormik.errors.email && (
            <span className="text-red-500 text-sm mt-1">
              {signUpFormik.errors.email}
            </span>
          )}
        </div>

        <div className="mb-4">
          <div className="relative">
            {/* Toggle Password Visibility Button */}
            <button
              className="absolute top-1/2 -translate-y-1/2 right-2"
              onClick={() => setShowPassword((old) => !old)}
              type="button"
            >
              {!showPassword ? (
                <FaEye className="text-black text-base" />
              ) : (
                <FaEyeSlash className="text-black text-base" />
              )}
            </button>

            {/* Password Input Field */}
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={signUpFormik.values.password}
              onChange={signUpFormik.handleChange}
              placeholder="Password"
              className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
                signUpFormik.touched.password && signUpFormik.errors.password
                  ? "border-red-500"
                  : ""
              }`}
            />
          </div>

          {/* Error Message */}
          {signUpFormik.touched.password && signUpFormik.errors.password && (
            <span className="text-red-500 text-sm mt-1">
              {signUpFormik.errors.password}
            </span>
          )}
        </div>

        {/* Email Login Button */}
        <div className="w-full flex gap-2">
          <button
            className="font-sora !bg-[#F7D504] !text-[#1A1A1A] w-full py-2 rounded hover-shadow-yellow transition-shadow duration-300"
            type="submit"
          >
            Sign up
          </button>
        </div>

        <div className="my-4 flex items-center justify-center">
          <span className="font-sora text-gray-500">OR CONTINUE WITH</span>
        </div>

        {/* Google Login Button */}
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-[300px] py-2 bg-white border border-gray-300 rounded-[25px] hover:bg-gray-100 transition-colors"
        >
          <img
            src={GoogleLogo}
            alt="Google logo"
            className="w-5 h-5 mr-2 rounded-full"
          />
          <span className="font-sora text-gray-600 font-medium">
            Login with Google
          </span>
        </button>
        <span className="tracking-wide mt-2">
          Already have account!{" "}
          <button
            className="font-sora bg-transparent !text-[#f7d504]"
            onClick={() => navigate("/login")}
          >
            Log In
          </button>{" "}
        </span>
      </form>
    </div>
  );
};

export default SignUpForm;
