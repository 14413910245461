import { v4 as uuidv4 } from "uuid";
import { getFromLocal } from "../utils/storage";

export const createWebSocket = (url, onMessageCallback) => {
  let ws;
  let heartbeatInterval;
  let retryAttempts = 0;
  const maxRetries = 5;
  const userData = getFromLocal("user");

  // Generate a random UID for the connection
  const uid = `${userData?.email ?? ""}_${uuidv4()}`;

  const connect = () => {
    if (!url) {
      console.error("WebSocket URL is not provided");
      return;
    }

    if (!ws || ws.readyState === WebSocket.CLOSED) {
      ws = new WebSocket(url);

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            event: "CONNECTED",
            uid,
            // uid: `${userData?.email ?? ""}_${uid}`,
          })
        );

        // Reset retry attempts on successful connection
        retryAttempts = 0;

        // Start heartbeat
        heartbeatInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            console.log("Sending heartbeat to keep connection alive");
            ws.send(JSON.stringify({ event: "PING" }));
          }
        }, 15000); // Adjusted to every 15 seconds
      };

      ws.onmessage = (event) => {
        const parsedMessage = JSON.parse(event.data);

        // Pass the message to the callback function
        if (onMessageCallback) {
          onMessageCallback(parsedMessage);
        }
      };

      ws.onclose = (event) => {
        clearInterval(heartbeatInterval);

        // Handle abnormal closure
        if (event.code !== 1000) {
          console.error(
            "WebSocket closed abnormally:",
            event.code,
            event.reason
          );

          // Attempt to reconnect if the closure was abnormal
          if (retryAttempts < maxRetries) {
            retryAttempts++;
            console.log(
              `Attempting to reconnect... (${retryAttempts}/${maxRetries})`
            );
            setTimeout(connect, 3000);
          } else {
            console.error("Max retries reached. Could not reconnect.");
          }
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error", error);
      };
    }
  };

  const sendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(message);
    } else {
      console.error("WebSocket is not open");
    }
  };

  const disconnect = () => {
    if (ws) {
      ws.close();
    }
  };

  return { connect, sendMessage, disconnect, uid };
};
