import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { useAskBotMutation } from "../../features/senaBotApiSlice";

import Header from "../../components/atom/Header";
import HomeBanner from "../../components/molecule/HomeBanner";
import Services from "../../components/molecule/Services";
import AboutUs from "../../components/molecule/AboutUs";
import OurBlog from "../../components/molecule/OurBlog";
import { ContactUs } from "../../components/molecule/ContactUs";
import { Careers } from "../../components/molecule/Careers";
import { Footer } from "../../components/atom/Footer";
import RagLoader from "../../components/atom/RagLoader";
import axios from "axios";
import { getFromLocal } from "../../utils/storage";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState("");
  const [question, setQuestion] = useState("");
  const [serviceData, setServiceData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [homeStrapiData, setHomeStrapiData] = useState(null);
  const user = getFromLocal("user");

  const servicesRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const blogsRef = useRef(null);
  const careersRef = useRef(null);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [askBot, { isLoading: botDataIsLoading }] = useAskBotMutation();

  const servicesSectionRedirect = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const aboutUsSectionRedirect = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const contactUsSectionRedirect = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const blogSectionRedirect = () => {
    if (blogsRef.current) {
      blogsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const careerSectionRedirect = () => {
    if (careersRef.current) {
      careersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSenaBotService = async (query) => {
    if (!user) {
      return navigate(`/login?redirect=/&query=${query}`);
    }
    try {
      await askBot({ query })
        .unwrap()
        .then((resData) => {
          if (resData.data.section === "About Us") {
            setAboutData(resData);
          } else if (resData.data.section === "Services") {
            setQuestion(query);
            setServiceData(resData);
          }
          if (resData.data.section === "About Us" && aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: "smooth" });
            aboutUsSectionRedirect();
          } else if (resData.data.section === "Contact" && contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: "smooth" });
            contactUsSectionRedirect();
          } else if (
            resData?.data?.section === "Services" &&
            servicesRef.current
          ) {
            servicesRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (resData.data.section === "Blog" && blogsRef.current) {
            blogsRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (resData.data.section === "Careers" && careersRef.current) {
            careersRef.current.scrollIntoView({ behavior: "smooth" });
          } else if (resData.data.section === "Sales") {
            navigate(`/chatbot?ques=${query}`);
          }
        });

      setQuery("");
    } catch (error) {
      console.error("Error fetching data:", error);
      setQuery("");
    }
  };

  useEffect(() => {
    const fetchStrapiData = async () => {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/api/home-page?populate[services][populate]=sections.image&populate[slider][populate]=image,options,desktopBanner&populate[abouts][populate]=boxes,image&populate[contactus][populate]=image`,
        headers: {},
      };

      try {
        const response = await axios.request(config);
        console.log("Home strapi Data:", response.data.data);
        setHomeStrapiData(response.data.data);
      } catch (error) {
        console.error("Error fetching career data:", error);
      }
    };

    fetchStrapiData();
  }, []);

  useEffect(() => {
    const handleQuery = (query) => {
      if (query) {
        handleSenaBotService(query);
        navigate(location?.pathname, { state: { reload: true } });
      }
    };

    const searchQuery = searchParams?.get("query") ?? "";
    const quesQuery = localStorage.getItem("query") ?? "";

    if (quesQuery) {
      handleQuery(quesQuery);
      localStorage.removeItem("query");
    } else if (searchQuery) {
      handleQuery(searchQuery);
    }
  }, [searchParams, location?.pathname]);

  return (
    <div>
      {botDataIsLoading && <RagLoader />}
      <Header
        aboutUsSectionRedirect={aboutUsSectionRedirect}
        contactUsSectionRedirect={contactUsSectionRedirect}
        servicesSectionRedirect={servicesSectionRedirect}
        blogSectionRedirect={blogSectionRedirect}
        careerSectionRedirect={careerSectionRedirect}
      />
      <HomeBanner
        query={query}
        setQuery={setQuery}
        setQuestion={setQuestion}
        onSubmit={handleSenaBotService}
        botDataIsLoading={botDataIsLoading}
        homeStrapiData={homeStrapiData}
      />
      <Services
        ref={servicesRef}
        ragAIData={serviceData}
        homeStrapiData={homeStrapiData?.attributes?.services}
        question={question}
      />
      <AboutUs
        ref={aboutRef}
        ragAIData={aboutData}
        homeStrapiData={homeStrapiData}
      />
      <ContactUs ref={contactRef} homeStrapiData={homeStrapiData} />
      <OurBlog ref={blogsRef} />
      <Careers ref={careersRef} />
      <Footer />
    </div>
  );
};

export default Home;
