import React from "react";
import { Link } from "react-router-dom";
import ForgetForm from "../components/molecule/forms/forgetForm";
import Logo from "../assets/icon/senaLogo.svg";

const ForgetPasswordPage = () => {
  return (
    <div>
      <div className="flex items-center justify-center p-4">
        <Link to="/">
          <img
            src={Logo}
            alt="logo"
            className="w-[100px] md:w-[110px] h-auto cursor-pointer"
          />
        </Link>
      </div>
      <ForgetForm />
    </div>
  );
};

export default ForgetPasswordPage;
