import { createSlice } from "@reduxjs/toolkit";

const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState: {
    abouUsStoredData: [],
  },
  reducers: {
    setAboutUsData(state, action) {
      state.abouUsStoredData = action.payload;
    },
  },
});

export const { setAboutUsData } = aboutUsSlice.actions;
export default aboutUsSlice.reducer;
