import React from "react";
import LoaderIcon from "../../assets/gif/loading.gif";

const RagLoader = () => {
  return (
    <div className="fixed h-full w-full bg-[#0000009d] flex items-center justify-center z-50">
      <div className="flex flex-col justify-center gap-1 items-center bg-[#96969675] border-1 border-[#e4e4e4] p-3 rounded-md">
        <img src={LoaderIcon} alt="loaderIcon" className="w-[50px]" />
        <p className="text-white text-[13px]">Loading ...</p>
      </div>
    </div>
  );
};

export default RagLoader;
