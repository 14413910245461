import apiSlice from "../../src/app/api/apiSlice";

export const senaBotApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getData: builder.query({
    //   query: (queries) => {
    //     let queryString = "";
    //     for (const key in queries) {
    //       if (queries[key]) {
    //         queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
    //           queries[key]
    //         }`;
    //       }
    //     }
    //     return {
    //       url: `/endpoint${queryString}`,
    //     };
    //   },
    //   providesTags: [],
    // }),
    askBot: builder.mutation({
      query: (body) => ({
        url: "/rag/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const { useAskBotMutation } = senaBotApiSlice;
