import React from "react";
import AISearchBot from "../atom/AISearchBot";

export const InterviewProcess = ({ steps }) => {
  return (
    <div className="w-full">
      <div className="sectionContainer max-w-[1700px] responsiveWidth">
        <div className=" pt-[41px] pb-[75px] md:pt-[54px] md:pb-[46px]">
          <div className=" flex flex-col gap-10 border-t border-white">
            <h1 className="font-sora text-left text-[28px] pt-[54px] md:text-[44px]font-semibold leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px]">
              Interview process
            </h1>

            <div className="relative flex flex-col lg:flex-row items-start gap-10">
              <div className="w-full md:w-[80%] lg:w-[40%] flex flex-col md:flex-col">
                <h2 className="font-sora text-left text-[16px] md:text-[32px] font-[300] leading-[40.4px] md:leading-[45.52px] lg:leading-[55.44px]">
                  Step-by-step
                </h2>
              </div>
              <div className="w-full md:w-[80%] lg:w-[50%] flex justify-center">
                <div className="font-sora text-[16px] font-normal leading-[31.2px]">
                  {steps.map((step, index) => (
                    <p key={index} className="mb-4">
                      {step.children[0]?.text || ""}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
