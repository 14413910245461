import React, { useEffect, useState } from "react";
import { RiMenu3Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import UserIcon from "../../assets/icon/userIcon.svg";

import ExportIcon from "../../assets/image/uploadIcon.png";
import { getFromLocal } from "../../utils/storage";
import Tooltip from "./toolTip";
import { FiLogOut } from "react-icons/fi";
import { hasNonEmptyValue } from "../../utils";

const SideBar = ({ memoryData, setSideBar, generatePdf }) => {
  let authUser = getFromLocal("user");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);
  const [user, setUser] = useState(null);

  const saveToLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  // Recursive function to render the object keys and values
  const renderValues = (obj, level = 0) => {
    return Object.keys(obj).map((key, index) => {
      const value = obj[key];
      if (typeof value === "object" && value !== null) {
        if (hasNonEmptyValue(value)) {
          return (
            <div key={index} className={`ml-${level * 4}, pl-5`}>
              <p className="font-sora text-white capitalize font-bold">
                {key.replace(/_/g, " ")}
              </p>
              {renderValues(value, level + 1)}{" "}
              {/* Recursively render nested objects */}
            </div>
          );
        }
      } else {
        // Render key-value pairs only if value is non-empty
        return value && value !== "" ? (
          <p key={index} className={`ml-${level * 4} font-sora text-gray-400`}>
            {`${key.replace(/_/g, " ")}: ${value}`}
          </p>
        ) : null;
      }
      return null;
    });
  };

  // Open Sidebar toggle
  const handleSideBar = () => {
    setSideBar((sideView) => !sideView);
  };

  // User Login function
  const handleGoogleLogin = () => {
    navigate("/login?redirect=/chatbot");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userString = urlParams.get("user");
    let userData;
    if (userString) {
      const userObject = {};
      const regex = /(\w+)=['"]([^'"]+)['"]/g;
      let match;
      while ((match = regex.exec(userString)) !== null) {
        userObject[match[1]] = match[2];
      }

      userData = {
        userId: userObject?.id,
        email: userObject?.email,
        firstName: userObject?.first_name,
        lastName: userObject?.last_name,
        displayName: userObject?.display_name,
        profilePicture: userObject?.picture,
        provider: userObject?.provider,
      };
      saveToLocal("user", userData);
      navigate(location.pathname, { replace: true });
    } else {
      userData = getFromLocal("user");
    }
    setUser(userData);
    authUser = getFromLocal("user");
  }, []);

  const handleLogoutClick = () => {
    setIsLogoutPopupOpen(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userLoggedIn");
    window.location.reload();
  };

  const cancelLogout = () => {
    setIsLogoutPopupOpen(false);
  };

  return (
    <div className="relative z-[800] font-sora bg-[#2F2F2F] w-full py-5 h-full text-white overflow-y-auto">
      <div className="">
        <div className="flex justify-between px-5 pb-4 border-b border-[#1a1a1a]">
          <h1 className="font-sora text-xl font-bold">Memory</h1>
          <div
            className="cursor-pointer"
            onClick={handleSideBar}
            role="presentation"
          >
            <RiMenu3Line size={30} />
          </div>
        </div>
        <div className="pt-4 px-5">
          {memoryData?.map((item, index) => (
            <div key={index} className="data-block">
              {item.memoryData ? (
                Object.keys(item.memoryData).map((section, secIndex) => {
                  // Check if the section contains any non-empty value before rendering
                  return hasNonEmptyValue(item.memoryData[section]) ? (
                    <div
                      key={secIndex}
                      className={`section-block mb-5 ${
                        secIndex > 0 ? "border-b border-[#1a1a1a] py-3" : ""
                      }`}
                    >
                      <h2 className="font-sora text-[16px] font-bold capitalize">
                        {section.replace(/_/g, " ").toUpperCase()}
                      </h2>
                      {renderValues(item.memoryData[section], 1)}{" "}
                      {/* Render values starting at level 1 */}
                    </div>
                  ) : null;
                })
              ) : (
                <p>No memory data available</p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Render logout popup */}
      {isLogoutPopupOpen && (
        <div className="fixed inset-0 bg-[#444444a2] bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-[#2f2f2f] p-5 rounded-md text-white">
            <h2 className="font-sora font-bold">Logout</h2>
            <p
              className="font-sora"
              onClick={handleLogoutClick}
              role="presentation"
            >
              Are you sure you want to logout?
            </p>
            <div className="flex justify-end mt-4 text-[#1A1A1A]">
              <button
                onClick={cancelLogout}
                className="bg-gray-300 p-2 rounded-[20px] mr-2"
              >
                Cancel
              </button>
              <button
                onClick={confirmLogout}
                className="bg-[#F7D504] p-2 rounded-[20px]"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className={`fixed bottom-0 w-[-webkit-fill-available] items-center flex-row-reverse px-5 py-4 h-fit flex justify-between gap-3 bg-[#2F2F2F] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.75)] overflow-x-hidden hover:overflow-x-visible`}
      >
        <div
          className="flex items-center gap-2"
          onClick={generatePdf}
          role="presentation"
        >
          <Tooltip text="Export chat">
            <img
              src={ExportIcon}
              alt="exportIcon"
              className="cursor-pointer w-[40px] h-auto border-[1px] border-[#ffffff] rounded-[25px]"
            />
          </Tooltip>
        </div>
        <div
          onClick={authUser ? () => {} : handleGoogleLogin}
          className="cursor-pointer text-[#1A1A1A] font-sora font-normal text-[16px] max-w-[180px] w-full"
          role="presentation"
        >
          {authUser ? (
            <div className="group flex items-center gap-2 relative">
              <div className="flex items-center space-x-2 p-[2px] rounded-full">
                <img
                  src={user?.profilePicture ?? UserIcon}
                  alt={user?.displayName ?? "User"}
                  title={user?.email ?? authUser?.email ?? "user"}
                  className="w-9 h-9 rounded-full"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = UserIcon;
                  }}
                  role="presentation"
                />
              </div>
              <p className="font-sora text-[13px] text-gray-400">
                {user?.displayName ?? authUser?.email ?? user?.email ?? "User"}
              </p>
              <ul className="invisible group-hover:visible group-hover:opacity-100 opacity-0 max-w-[180px] left-0 bottom-0 -translate-y-[10px] group-hover:-translate-y-[50px] w-full py-1 rounded-md absolute bg-[#1a1a1a] border border-[#2f2f2f] transition-all">
                <li
                  className="flex gap-2 items-center w-full text-sm text-white px-3 py-2 cursor-pointer hover:text-[#F7D504] transition-all"
                  onClick={handleLogoutClick}
                  role="presentation"
                >
                  <FiLogOut />
                  Log Out
                </li>
              </ul>
            </div>
          ) : (
            <p className="w-[80px] px-[15px] py-[5px] bg-[#F7D504] rounded-full hover-shadow-yellow transition-shadow duration-300">
              Login
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
