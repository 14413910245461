import React from "react";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "../../../validator/auth";
import { useForgotPasswordMutation } from "../../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgetForm = () => {
  const navigate = useNavigate();
  const [forget] = useForgotPasswordMutation();

  const initialState = {
    email: "",
  };

  const forgetFormik = useFormik({
    initialValues: initialState,
    validationSchema: forgetPasswordSchema,
    onSubmit: async (e, { resetForm }) => {
      try {
        await forget(e).unwrap();
        resetForm();
        toast.success("Forget successful");
        navigate("/login");
      } catch (error) {
        console.error("error>>>", error);
        toast.error(error?.data?.detail ?? "Failed to signup");
      }
    },
  });

  return (
    <div className=" flex flex-col items-center justify-center mb-52 mt-2 p-10 ">
      <form
        noValidate
        onSubmit={forgetFormik.handleSubmit}
        className="flex flex-col items-center max-w-[300px]"
      >
        <h1 className="font-sora text-center mb-4 text-[22px] font-bold">
          Forget password
        </h1>

        <div className="mb-4">
          {/* Email Input */}
          <input
            type="email"
            name="email"
            value={forgetFormik.values.email}
            onChange={forgetFormik.handleChange}
            placeholder="name@example.com"
            className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
              forgetFormik.touched.email && forgetFormik.errors.email
                ? "border-red-500"
                : ""
            }`}
          />
          {/* Error Message */}
          {forgetFormik.touched.email && forgetFormik.errors.email && (
            <span className="text-red-500 text-sm mt-1">
              {forgetFormik.errors.email}
            </span>
          )}
        </div>

        {/* Email Login Button */}
        <div className="w-full flex gap-2">
          <button
            className="font-sora !bg-[#F7D504] !text-[#1A1A1A] w-full py-2 rounded hover-shadow-yellow transition-shadow duration-300"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgetForm;
