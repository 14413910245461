import React, { useState } from "react";
import { useFormik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { resetPasswordSchema } from "../../../validator/auth";
import { useResetPasswordMutation } from "../../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import { getFromLocal } from "../../../utils/storage";

const ResetPasswordForm = () => {
  const initialState = {
    password: "",
    token: getFromLocal("user")?.token || "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword] = useResetPasswordMutation();

  const resetFormik = useFormik({
    initialValues: initialState,
    validationSchema: resetPasswordSchema,
    onSubmit: async (e, { resetForm }) => {
      try {
        await resetPassword(e).unwrap();
        resetForm();
        toast.success("Reset successful");
      } catch (error) {
        console.error("error>>>", error);
        toast.error(error?.data?.detail ?? "Failed to signup");
      }
    },
  });

  return (
    <div className=" flex flex-col items-center justify-center mb-52 mt-2 p-10 ">
      <form
        noValidate
        onSubmit={resetFormik.handleSubmit}
        className="flex flex-col items-center max-w-[300px]"
      >
        <h1 className="font-sora text-center mb-4 text-[22px] font-bold">
          Reset password
        </h1>

        <div className="mb-4">
          <div className="relative">
            {/* Toggle Password Visibility Button */}
            <button
              className="absolute top-1/2 -translate-y-1/2 right-2"
              onClick={() => setShowPassword((old) => !old)}
              type="button"
            >
              {!showPassword ? (
                <FaEye className="text-black text-base" />
              ) : (
                <FaEyeSlash className="text-black text-base" />
              )}
            </button>

            {/* Password Input Field */}
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={resetFormik.values.password}
              onChange={resetFormik.handleChange}
              placeholder="Password"
              className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
                resetFormik.touched.password && resetFormik.errors.password
                  ? "border-red-500"
                  : ""
              }`}
            />
          </div>

          {/* Error Message */}
          {resetFormik.touched.password && resetFormik.errors.password && (
            <span className="text-red-500 text-sm mt-1">
              {resetFormik.errors.password}
            </span>
          )}
        </div>

        {/* Email Login Button */}
        <div className="w-full flex gap-2">
          <button
            className="font-sora !bg-[#F7D504] !text-[#1A1A1A] w-full py-2 rounded hover-shadow-yellow transition-shadow duration-300"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
