export function toSentenceCase(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// Helper function to check if an object has any non-empty values
export const hasNonEmptyValue = (obj) => {
  return Object.values(obj).some((value) => {
    if (typeof value === "object" && value !== null) {
      return hasNonEmptyValue(value); // Recursively check nested objects
    }
    return value && value !== ""; // Check for non-empty and non-null values
  });
};
