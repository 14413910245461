import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurBlog = React.forwardRef((_, ref) => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchBlogs = (page) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/articles?populate[Image][fields][0]=url&pagination[page]`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setBlogs(response.data.data);
        } else {
          console.error("Data is not an array:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const handleBlogClick = (id) => {
    navigate(`/blogDetail/${id}`);
  };

  // Carousel responsiveness settings
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button
          className={currentSlide === 0 ? "disable" : ""}
          onClick={() => previous()}
        >
          <IoIosArrowBack className="text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -left-[5px] md:left-[0px] xl:left-[-60px]" />
        </button>
        <button onClick={() => next()}>
          <IoIosArrowForward className="text-black text-[40px] bg-white p-2 rounded-[8px] absolute top-[34%] -right-[5px] md:right-[0px] xl:right-[-60px]" />
        </button>
      </div>
    );
  };

  const blogRedirect = () => {
    navigate("/blogs");
  };

  return (
    <div
      ref={ref}
      className="w-full border-b border-white"
      style={{
        background:
          "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
      }}
    >
      <div className="sectionContainer max-w-[1468px] responsiveWidth">
        <div className="w-full py-[80px] similarExpTopSec">
          <h2 className="font-sora text-[40px] md:text-[52px] lg:text-[60px] font-semibold leading-[50.4px] md:leading-[65.52px] lg:leading-[75.6px] text-left mb-[45px]">
            Our Blog
          </h2>
          <div className="relative">
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              customTransition="all 0.5s ease"
              transitionDuration={1000}
              renderButtonGroupOutside={true}
              arrows={false}
              customButtonGroup={<ButtonGroup />}
            >
              {blogs.map((blog, index) => {
                const { attributes } = blog;
                const imageUrl = attributes?.Image?.data?.[0]?.attributes?.url;
                const contentText =
                  attributes?.Content?.[0]?.children?.[0]?.text || "";

                return (
                  <div key={blog.id} onClick={() => handleBlogClick(blog.id)}>
                    <div className="p-2 cursor-pointer">
                      {imageUrl && (
                        <img
                          src={`${baseUrl}${imageUrl}`}
                          alt={`Blog Image ${index + 1}`}
                          className="w-full h-[250px] md:h-[175px] lg:h-[288px] rounded-[25px] object-cover"
                        />
                      )}
                    </div>
                    <p className="py-[5px] px-[15px] text-[13px] md:text-[16px]">
                      {contentText}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="w-full flex justify-end md:pt-[40px] lg:pt-[60px]">
            <button
              onClick={blogRedirect}
              className="bg-[#F7D504] text-[16px] font-[600] p-3 md:px-[33px] md:py-[16px] rounded-[17px] text-[#1A1A1A] hover-shadow-yellow transition-shadow duration-300"
            >
              See all blogs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default OurBlog;
