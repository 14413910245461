import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import AISearchBot from "../atom/AISearchBot";

import StripeImg from "../../assets/image/stripeImg.png";

const HomeBanner = ({
  query,
  setQuery,
  onSubmit,
  botDataIsLoading,
  homeStrapiData,
}) => {
  console.log("window.innerHeight", typeof window.innerHeight);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Fetch slider data from homeStrapiData
  const sliderContent = homeStrapiData?.attributes?.slider || [];
  console.log("🚀 ~ sliderContent:", sliderContent);

  const renderHeadingWithAsterisk = (heading) => {
    const words = heading.split(" ");
    return words.map((word, index) => {
      const cleanWord = word.replace(/\*/g, ""); // Remove asterisks

      return (
        <React.Fragment key={index}>
          {cleanWord}
          {word.includes("*") && (
            <span className="relative">
              <img
                src={StripeImg}
                alt="stripeImg"
                className="absolute bottom-[-3vw] right-[-3vw] max-w-[30vw]  md:bottom-[-2vw] md:right-[-3vw] md:max-w-[16vw] lg:bottom-[-2vw] lg:right-[-2vw] lg:max-w-[16vw]"
              />
            </span>
          )}
          {index < words.length - 1 && " "} {/* Add space between words */}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="sectionContainer">
      <div className="flex relative">
        <Swiper
          slidesPerView={1.1}
          spaceBetween={12}
          slideToClickedSlide={true}
          centeredSlides={true}
          speed={1000}
          pagination={{
            clickable: true,
            el: ".my-custom-pagination",
          }}
          initialSlide={0}
          cssMode={true}
          modules={[FreeMode, Pagination, Autoplay]}
          className="mySwiper"
        >
          {sliderContent.map((slider, index) => (
            <SwiperSlide key={index}>
              <div
                className="xl:relative h-[96%] mb-[34px] xl:h-[73vh] flex flex-col-reverse justify-end xl:justify-start xl:flex-row items-center gap-2 py-[25px] px-[26px] md:py-[50.08px] lg:px-[40px] lg:py-[36.42px] lg:pr-[64.37px] xl:pl-[100px] rounded-[25px]"
                style={{
                  background:
                    "linear-gradient(71.36deg, #000000 31.72%, #666666 131.95%)",
                }}
              >
                <img
                  src={`${baseUrl}${slider.desktopBanner?.data?.attributes?.url}`}
                  alt={`${baseUrl}${slider.desktopBanner?.data?.attributes?.url}`}
                  className="xl:absolute right-0 top-1/2 xl:-translate-y-1/2 w-full h-full object-cover object-right xl:block hidden rounded-[25px]"
                />
                <div
                  className={`w-full xl:w-[56%] max-sm:h-full max-sm:flex max-sm:flex-col max-sm:justify-around relative z-10 ${
                    index === 0 || index === 2 ? "max-sm:gap-4" : ""
                  }`}
                >
                  <h2
                    className={`text-[8vw] md:text-[4vw] lg:text-[4vw] font-sora font-semibold leading-[10vw] md:leading-[5vw] lg:leading-[5.3vw] text-left text-white ${
                      index === 0 || index === 2
                        ? "max-sm:text-4xl max-sm:leading-snug"
                        : ""
                    }`}
                  >
                    {renderHeadingWithAsterisk(slider.heading)}
                  </h2>

                  {/* Render the grid only for the options based banner */}
                  {slider.sliderType === "heading_type" ? (
                    <p
                      className={`font-jakarta min-w-[20vw] md:min-w-[40vw] text-[16px] lg:text-[20px] font-normal leading-[24px] lg:leading-[30.8px] text-left text-white md:mt-4 max-sm:min-h-[50%] ${
                        index === 0 || index === 2
                          ? "max-sm:text-xl max-sm:leading-snug"
                          : ""
                      }`}
                    >
                      {slider.description}
                    </p>
                  ) : (
                    <div
                      className={`w-[100%] lg:w-[75%] grid grid-cols-1 md:grid-cols-2 gap-5 mt-8 ${
                        window.innerHeight < 1300 &&
                        window.innerWidth < 1600 &&
                        "lg:w-[90%]"
                      } `}
                    >
                      {slider.options.map((option, optIndex) => (
                        <div key={optIndex} className="relative ">
                          <div
                            className={`absolute h-[50px] w-[50px] bg-white z-[0] ${
                              optIndex === 0 ? "right-10 top-4" : ""
                            }, ${optIndex === 1 ? "right-10 top-5" : ""} 
                            ${optIndex === 2 ? "right-28 top-5" : ""}
                            ${optIndex === 3 ? "right-16 top-5" : ""}`}
                            style={{
                              background:
                                optIndex === 0
                                  ? "#F9E633"
                                  : optIndex === 1
                                  ? "#ffffff"
                                  : optIndex === 2
                                  ? "#E7D1AE"
                                  : optIndex === 3
                                  ? "#B0E6F7"
                                  : "#F9E633",
                            }}
                          ></div>
                          <div className="backdrop-blur-[42px] border rounded-[15px] p-2 bg-[#333333]/50 text-white">
                            <div className=" relative flex items-center gap-4 mb-1 md:mb-2">
                              <div className="bg-[#F9E633] text-black text-[14px] py-1 px-3 rounded-full">
                                {option.serial}
                              </div>
                              <h3 className="font-sora font-semibold text-[14px] md:text-[16px] truncate">
                                {option.heading}
                              </h3>
                            </div>
                            <p className="relative text-[12px] font-jakarta leading-2 md:leading-5 md:min-h-[60px] min-h-6 line-clamp-3">
                              {option.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="w-full flex justify-center md:justify-center lg:w-[40%] xl:hidden">
                  <img
                    src={`${baseUrl}${slider.image?.data?.attributes?.url}`}
                    alt={`${baseUrl}${slider.image?.data?.attributes?.url}`}
                    className="w-full md:w-[60%] lg:w-full max-w-full md:max-w-[724.4px] h-auto"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Custom pagination element outside the slider */}
        <div className="swiper-pagination my-custom-pagination" />

        {/* AISearchBot */}
        <div className="w-full fixed z-50 left-0 bottom-[3vh] md:bottom-[5vh] lg:bottom-10 ">
          <AISearchBot
            query={query}
            setQuery={setQuery}
            onSubmit={onSubmit}
            botDataIsLoading={botDataIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
