import { createSlice } from "@reduxjs/toolkit";

const initialChatResponse = {
  chatResData: [],
};

const chatDataSlice = createSlice({
  name: "chat",
  initialState: initialChatResponse,
  reducers: {
    storeChatData(state, action) {
      state.chatResData.push(action.payload);
    },
    newResponse(state, action) {
      state.chatResData = action.payload;
    },
  },
});

export const { storeChatData, newResponse } = chatDataSlice.actions;
export default chatDataSlice.reducer;
