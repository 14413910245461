import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import GoogleLogo from "../../../assets/image/googleLogo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoginMutation } from "../../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import { logInSchema } from "../../../validator/auth";

const GoogleLoginForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialState = {
    username: "",
    password: "",
  };
  const [showPassword, setShowPassword] = useState(false);

  const [logIn] = useLoginMutation();

  const redirectUrl =
    searchParams.get("redirect") || localStorage.getItem("redirect") || "";
  const queryParams = searchParams.get("query") || "";

  const loginFormik = useFormik({
    initialValues: initialState,
    validationSchema: logInSchema,
    onSubmit: async (e, { resetForm }) => {
      try {
        // Attempt to log in the user and retrieve the access token
        const res = await logIn(e).unwrap();
        const userData = {
          email: e?.username || "",
          token: res?.access_token ?? "",
        };

        // Save user data and set local storage flags
        resetForm();
        saveToLocal("user", userData);
        saveToLocal("userLoggedIn", true);
        toast.success("Log in successful");

        // Handle navigation after login
        const queryParam = queryParams ? `?query=${queryParams}` : "";
        const targetUrl = redirectUrl + queryParam;

        if (redirectUrl) {
          localStorage.removeItem("redirect");
          navigate(targetUrl);
        } else if (location?.pathname === "/chatbot") {
          navigate(location.pathname, { state: { reload: true } });
        } else if (location?.pathname === "/login") {
          navigate("/");
        }
      } catch (error) {
        // Error handling
        toast.error("Failed to login");
        console.error("Login error:", error);
      }
    },
  });

  const saveToLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const handleGoogleLogin = () => {
    const user = localStorage.getItem("user");
    if (!user) {
      saveToLocal("redirect", redirectUrl);
      saveToLocal("query", queryParams);
      window.location.href = `${process.env.REACT_APP_BASE_URL}/google/login`;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userString = urlParams.get("user");

    if (userString) {
      const userObject = {};
      const regex = /(\w+)=['"]([^'"]+)['"]/g; // Regular expression to match key="value" or key='value'
      let match;
      while ((match = regex.exec(userString)) !== null) {
        userObject[match[1]] = match[2]; // match[1] is key, match[2] is value
      }

      const user = {
        userId: userObject?.id,
        email: userObject?.email,
        firstName: userObject?.first_name,
        lastName: userObject?.last_name,
        displayName: userObject?.display_name,
        profilePicture: userObject?.picture,
        provider: userObject?.provider,
      };
      saveToLocal("user", user);
      saveToLocal("userLoggedIn", true);
    }
  }, []);

  return (
    <div className=" flex flex-col items-center justify-center mb-52 mt-2 p-10 max-sm:p-2">
      <form
        noValidate
        onSubmit={loginFormik.handleSubmit}
        className="flex flex-col items-center max-w-[300px]"
      >
        <h1 className="font-sora text-center mb-4 text-[22px] font-bold">
          Login to use Sena
        </h1>

        <div className="mb-4">
          {/* Email Input */}
          <input
            type="email"
            name="username"
            value={loginFormik.values.username}
            onChange={loginFormik.handleChange}
            placeholder="name@example.com"
            className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
              loginFormik.touched.username && loginFormik.errors.username
                ? "border-red-500"
                : ""
            }`}
          />
          {/* Error Message */}
          {loginFormik.touched.username && loginFormik.errors.username && (
            <span className="text-red-500 text-sm mt-1">
              {loginFormik.errors.username}
            </span>
          )}
        </div>

        <div className="mb-4">
          <div className="relative">
            {/* Toggle Password Visibility Button */}
            <button
              className="absolute top-1/2 -translate-y-1/2 right-2"
              onClick={() => setShowPassword((old) => !old)}
              type="button"
            >
              {!showPassword ? (
                <FaEye className="text-black text-base" />
              ) : (
                <FaEyeSlash className="text-black text-base" />
              )}
            </button>

            {/* Password Input Field */}
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={loginFormik.values.password}
              onChange={loginFormik.handleChange}
              placeholder="Password"
              className={`font-sora w-[300px] px-4 py-2 border text-black border-gray-300 focus:border-[#F7D504] rounded focus:outline-none ${
                loginFormik.touched.password && loginFormik.errors.password
                  ? "border-red-500"
                  : ""
              }`}
            />
          </div>

          {/* Error Message */}
          {loginFormik.touched.password && loginFormik.errors.password && (
            <span className="text-red-500 text-sm mt-1">
              {loginFormik.errors.password}
            </span>
          )}
        </div>

        {/* Email Login Button */}
        <div className="w-full flex gap-2">
          <button
            className="font-sora !bg-[#F7D504] !text-[#1A1A1A] w-2/3 py-2 rounded hover-shadow-yellow transition-shadow duration-300"
            type="submit"
          >
            Login
          </button>
          <button
            className="font-sora bg-transparent !text-[#f7d504] w-1/3 py-2 rounded transition-shadow duration-300"
            onClick={() => navigate("/forget")}
            type="button"
          >
            Forget
          </button>
        </div>

        <div className="my-4 flex items-center justify-center">
          <span className="font-sora text-gray-500">OR CONTINUE WITH</span>
        </div>

        {/* Google Login Button */}
        <button
          onClick={handleGoogleLogin}
          className="flex items-center justify-center w-[300px] py-2 bg-white border border-gray-300 rounded-[25px] hover:bg-gray-100 transition-colors"
          type="button"
        >
          <img
            src={GoogleLogo}
            alt="Google logo"
            className="w-5 h-5 mr-2 rounded-full"
          />
          <span className="font-sora text-gray-600 font-medium">
            Login with Google
          </span>
        </button>
        <span className="tracking-wide mt-2">
          Don't have account!{" "}
          <button
            className="font-sora bg-transparent !text-[#f7d504]"
            onClick={() => navigate("/signup")}
            type="button"
          >
            Sign up
          </button>
        </span>
      </form>
    </div>
  );
};

export default GoogleLoginForm;
