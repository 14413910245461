import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiMenu3Line } from "react-icons/ri";

import { getFromLocal } from "../../utils/storage";

import Logo from "../../assets/icon/senaLogo.svg";
import SenaGroupIcon from "../../assets/icon/senaYellowFlag.svg";
import SenaBotRoboIconNew from "../../assets/icon/senaAiIcon.png";

export const SearchBotHeader = ({ setSideBar, sideBar }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [mobileHeader, setMobileHeader] = useState(false);
  const [activeIcon, setActiveIcon] = useState("bot");
  const [menuIconVisible, setMenuIconVisible] = useState(!sideBar);
  const [animatePage, setAnimatePage] = useState("");

  const saveToLocal = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const handleLogoClick = () => {
    setAnimatePage("move-page-right");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    if (!sideBar) {
      // Show menu icon with delay after sidebar closes
      const timer = setTimeout(() => {
        setMenuIconVisible(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setMenuIconVisible(false);
    }
  }, [sideBar]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userString = urlParams.get("user");
    let userData;
    if (userString) {
      const userObject = {};
      const regex = /(\w+)=['"]([^'"]+)['"]/g;
      let match;
      while ((match = regex.exec(userString)) !== null) {
        userObject[match[1]] = match[2];
      }

      const userData = {
        userId: userObject?.id,
        email: userObject?.email,
        firstName: userObject?.first_name,
        lastName: userObject?.last_name,
        displayName: userObject?.display_name,
        profilePicture: userObject?.picture,
        provider: userObject?.provider,
      };
      saveToLocal("user", userData);
    } else {
      userData = getFromLocal("user");
    }
    setUser(userData);
  }, []);

  useEffect(() => {
    if (user?.profilePicture) {
    }
  }, [user]);

  const handleSideBar = () => {
    setSideBar((sideView) => !sideView);
  };

  // Debounce function to limit the rate of function calls
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  /* mobile screen */
  useEffect(() => {
    const handleResize = debounce(() => {
      setMobileHeader(window.innerWidth <= 575);
    }, 150); // 150ms debounce delay

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleHomeClick = () => {
    setActiveIcon("homeIcon");
    if (mobileHeader) {
      setAnimatePage("move-page-right");
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  };

  return (
    <>
      {mobileHeader ? (
        <div className={`w-full sticky top-0 z-50 bg-[#1a1a1a] ${animatePage}`}>
          <div className="sectionContainer max-w-full">
            <div className="py-[0px]">
              <div className="flex min-h-[50px] md:flex items-center justify-between py-7 px-4 mb-4 md:px-10 lg:px-20 w-full">
                <div className="flex justify-start w-1/3">
                  <div className="flex justify-center gap-2 w-[56px] bg-black py-2 px-2 rounded-[25px]">
                    <div
                      className="flex gap-2 items-center px-1 py-1 cursor-pointer rounded-[25px]"
                      style={{
                        backgroundColor:
                          activeIcon === "homeIcon" ? "white" : "transparent",
                      }}
                      onClick={handleHomeClick}
                      role="presentation"
                    >
                      <img
                        src={SenaGroupIcon}
                        alt="aiYellowIcon"
                        className="w-[17px] h-auto"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="flex justify-center w-1/3"
                  onClick={handleHomeClick}
                  role="presentation"
                >
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-[100px] h-auto md:px-5"
                  />
                </div>
                <div
                  className="cursor-pointer flex justify-end w-1/3"
                  onClick={handleSideBar}
                  role="presentation"
                >
                  <RiMenu3Line size={30} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`w-full sticky top-0 z-10 ${animatePage}`}>
          <div className="sectionContainer max-w-full">
            <div className="py-[0px]">
              <div className="flex min-h-[50px] md:flex items-center justify-between py-7 px-4 mb-4 md:px-10 lg:px-20 w-full bg-[#1a1a1a]">
                <div onClick={handleLogoClick} role="presentation">
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-[100px] md:w-[110px] h-auto cursor-pointer"
                  />
                </div>
                <img
                  src={SenaBotRoboIconNew}
                  alt="aiBotIcon"
                  className="w-8 h-auto invert"
                />
                <div>
                  {menuIconVisible && (
                    <div
                      className="cursor-pointer"
                      onClick={handleSideBar}
                      role="presentation"
                    >
                      <RiMenu3Line size={30} />
                    </div>
                  )}
                </div>

                {/* <ul className="flex space-x-5 lg:space-x-5">
                  <div>
                    <img
                      src={UploadIcon}
                      alt="uploadIcon"
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    onClick={handleGoogleLogin}
                    className="inline-block items-center cursor-pointer text-[#1A1A1A] font-sora font-normal text-[16px]"
                  >
                    {user ? (
                      <div className="flex items-center space-x-2 p-[2px] rounded-full">
                        <img
                          src={user?.profilePicture}
                          alt={user.displayName || "User"}
                          title={user?.email}
                          className="w-9 h-auto rounded-full"
                        />
                      </div>
                    ) : (
                      <p className="px-[15px] py-[5px] bg-[#F7D504] rounded-full hover-shadow-yellow transition-shadow duration-300">
                        Login
                      </p>
                    )}
                  </div>
                  <div className="cursor-pointer" onClick={handleSideBar}>
                    <RiMenu3Line size={30} />
                  </div>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
